import { ToastrService } from 'ngx-toastr';
import { ContactService } from './contact.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  currentLang: BehaviorSubject<string>  = new BehaviorSubject('en');
  HomeArr: BehaviorSubject<any>  = new BehaviorSubject([]);
  serviceArr: BehaviorSubject<any>  = new BehaviorSubject([]);
  constructor(private contactService:ContactService, private toastr:ToastrService) { }

  buildFormData(formData, data, parentKey?) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
}


async get(){
  try{
    const res = await this.contactService.get();
    if(res){
      this.HomeArr.next(res);
    }
  } catch(err){
    this.toastr.error('Error',err)
  }
}

async getService(){
  try{
    const res = await this.contactService.getservice();
    if(res){
      this.serviceArr.next(res);
    }
  } catch(err){
    this.toastr.error('Error',err)
  }
}
}
