<section class="service-page globle-page"></section>

<section class="home-service globle-border">
  <div class="container page-body-wrapper">
    <h4
      innerHTML="
    {{
        lang == 'en'
          ? serviceArr.data?.firstSection[0].title_en
          : serviceArr.data?.firstSection[0].title_ar
      }}"
    ></h4>
    <p
      class="pb-4"
      innerHTML="
    {{
        lang == 'en'
          ? serviceArr.data?.firstSection[0].description_en
          : serviceArr.data?.firstSection[0].description_ar
      }}"
    ></p>

    <div
      innerHTML="
    {{
        lang == 'en'
          ? serviceArr.data?.secondSection[0].description_en
          : serviceArr.data?.secondSection[0].description_ar
      }}"
    ></div>
    <!-- <ul class="pt-4 px-0">
      <li>
        {{ "li-1" | translate }}
      </li>
      <li>
        {{ "li-2" | translate }}
      </li>
      <li>
        {{ "li-3" | translate }}
      </li>
      <li>
        {{ "li-4" | translate }}
      </li>
      <li>
        {{ "li-5" | translate }}
      </li>
      <li>
        {{ "li-6" | translate }}
      </li>
      <li>
        {{ "li-7" | translate }}
      </li>
      <li>
        {{ "li-8" | translate }}
      </li>
    </ul> -->
    <div class="row m-0">
      <div
        class="col-lg-4"
        *ngFor="let item of serviceArr.data?.sevicesSection"
      >
        <div class="icon">
          <img
            [src]="
              item.id == 4
                ? '../../assets/images/icon-1.png'
                : item.id == 5
                ? '../../assets/images/icon-2.png'
                : '../../assets/images/icon-3.png'
            "
            alt=""
          />
        </div>
        <h3
          innerHTML="
        {{ lang == 'en' ? item.title_en : item.title_ar }}"
        ></h3>
        <p
          innerHTML="
        {{ lang == 'en' ? item.description_en : item.description_ar }}
        "
          class="mb-5 mb-lg-0"
        ></p>
      </div>

      <!-- <div class="col-lg-4">
        <div class="icon">
          <img src="../../assets/images/icon-1.png" alt="" />
        </div>
        <h3>{{ "AIR_FREIGHT" | translate }}</h3>
        <p class="mb-5 mb-lg-0">
          {{ "icon-1" | translate }}

          <br />
          {{ "icon-12" | translate }}
          <br />
          {{ "icon-13" | translate }}
        </p>
      </div> -->
      <!-- /col -->
      <!-- <div class="col-lg-4">
        <div class="icon">
          <img src="../../assets/images/icon-2.png" alt="" />
        </div>
        <h3>{{ "OCEAN_FREIGHT" | translate }}</h3>
        <p class="mb-5 mb-lg-0">
          {{ "icon-2" | translate }}

          <br />
          {{ "icon-22" | translate }}
        </p>
      </div> -->
      <!-- /col -->
      <!-- <div class="col-lg-4">
        <div class="icon">
          <img src="../../assets/images/icon-3.png" alt="" />
        </div>
        <h3>{{ "LAND_FREIGHT" | translate }}</h3>
        <p>
          {{ "icon-3" | translate }}

          <br />
          {{ "icon-31" | translate }}
        </p>
      </div> -->
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
  <!-- /container -->
</section>

<!-- /home-service -->
