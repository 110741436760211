import { HelperService } from './../service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  toggle = false;

  lang = '';
  HomeArr
  constructor(@Inject(DOCUMENT) private document: Document,private titleService:Title, private translate:TranslateService , private helper:HelperService) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.helper.currentLang.next(this.lang);
  }

  changeLanguage(lang) {
    localStorage.setItem('lang', 'en');
    this.langChanged(lang);
    this.translate.use(lang);
    if(lang == "en"){
      this.helper.HomeArr.subscribe(arr =>{ this.HomeArr = arr;  this.titleService.setTitle( this.HomeArr.data?.contactDetails?.contactName);})

    }else{
      this.helper.HomeArr.subscribe(arr =>{ this.HomeArr = arr;  this.titleService.setTitle( this.HomeArr.data?.contactDetails?.contactNameAr);})

    }


    this.helper.currentLang.next(lang);
    // location.reload();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0) {
      document.getElementById('brand').classList.add('scrolled');
    } else {
      document.getElementById('brand').classList.remove('scrolled');

    }
  }

  toggleNavbar() {
    this.toggle = !this.toggle;
  }

  langChanged(lang) {
    if (lang === 'ar') {
      // add bootstrap ar

      this.generateLinkElement({
        dir: 'rtl',
        lang: 'ar',
      });
      document.querySelector('#bootstrap-en').setAttribute('href','assets/vendor/bootstrap/css/bootstrap-ar.css');
    } else {
      // en
      this.generateLinkElement({
        dir: 'ltr',
        lang: 'en',
      });
      document.querySelector('#bootstrap-en').setAttribute('href','assets/vendor/bootstrap/css/bootstrap.css');
    }
  }
  generateLinkElement(props) {
    const htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('dir', props.dir);
    htmlEl.setAttribute('lang', props.lang);
  }

}
