import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { QuotesComponent } from './quotes/quotes.component';
import { TrackingComponent } from './tracking/tracking.component';
import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path:'home',
    component:HomeComponent
  },
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'services',
    component:ServicesComponent
  },
  {
    path:'tracking',
    component:TrackingComponent
  },
  {
    path:'tracking/:id',
    component:TrackingComponent
  },
  {
    path:'quotes',
    component:QuotesComponent
  },
  {
    path:'contact',
    component:ContactComponent
  },
  {
    path:'blog',
    component:BlogComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
