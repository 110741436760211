<section class="service-page globle-page"></section>

<section class="home-service globle-border">
  <div class="container page-body-wrapper">
    <div class="row">
      <div class="col-lg-8 order-lg-2">
        <h4>{{ "Blog" | translate }}</h4>
      </div>
      <div class="col-lg-4 order-lg-1">
        <ul>
          <li>
            {{ "Recent_Posts" | translate }}
          </li>
          <li>
            {{ "General_News" | translate }}
          </li>
          <li>
            {{ "Press_Releases" | translate }}
          </li>
        </ul>
      </div>
    </div>
    <!-- /row -->
  </div>
</section>
