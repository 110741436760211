<div class="container page-body-wrapper">
  <nav>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div
              class="logo cursor-pointer"
              id="brand"
              [routerLink]="['/home']"
            >
              <img src="../../assets/images/logo.png" alt="Al Awael" />
            </div>
            <!-- /logo -->
            <div>
              <div class="lang d-flex flex-column align-items-end mt-3">
                <p class="mb-2 font-weight-bold lang-text">
                  {{ "choose_a_language" | translate | uppercase }}
                </p>
                <div class="d-flex">
                  <img
                    src="../../assets/images/ar.png"
                    class="mr-3 flag cursor-pointer"
                    alt="ar"
                    (click)="changeLanguage('ar')"
                  />
                  <img
                    src="../../assets/images/en.png"
                    alt="en"
                    class="cursor-pointer flag"
                    (click)="changeLanguage('en')"
                  />
                </div>
              </div>
              <div class="icon-bar cursor-pointer" (click)="toggleNavbar()">
                <span [ngClass]="{ active: toggle }"></span>
              </div>
              <div class="menu">
                <div class="">
                  <div>
                    <ul [ngClass]="{ active: toggle }">
                      <li>
                        <a [routerLink]="['/home']">
                          {{ "Home" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/services']"
                          >{{ "Services" | translate }}
                        </a>
                      </li>

                      <li>
                        <a [routerLink]="['/tracking']">
                          {{ "Tracking" | translate }}
                        </a>
                      </li>

                      <li>
                        <a [routerLink]="['/quotes']">
                          {{ "Quotes" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/blog']">
                          {{ "Blog" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/contact']">
                          {{ "Contact_Us" | translate }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /container -->
              </div>
              <!-- /menu -->
            </div>
          </div>
          <!-- /d-flex -->
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </nav>
</div>
