<section class="service-page globle-page"></section>

<section class="home-service globle-border">
  <div class="container page-body-wrapper">
    <h4>{{ "Request_Quote" | translate }}</h4>
    <p class="mb-5">
      {{ "quotes_1" | translate }}
    </p>

    <div class="row">
      <div class="col-lg-10 col-xl-9">
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "name" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="qoutesForm.name"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Email" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="email"
                [(ngModel)]="qoutesForm.email"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Phone" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="qoutesForm.phone"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Origin" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="qoutesForm.origin"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Destination" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="qoutesForm.destination"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Shipping_Method" | translate }} </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <select
                class="form-control"
                [(ngModel)]="qoutesForm.shipping_method"
              >
                <option value="1">{{ "AIR_FREIGHT" | translate }}</option>
                <option value="2">{{ "OCEAN_FREIGHT" | translate }}</option>
                <option value="3">{{ "LAND_FREIGHT" | translate }}</option>
              </select>
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Width" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="qoutesForm.width"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Height" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="qoutesForm.height"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Depth" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="qoutesForm.depth"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Weight" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="qoutesForm.weight"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row mb-4">
          <div class="col-lg-4">
            <label class="pt-3">
              {{ "Describe_Cargo" | translate }} <b>*</b>
            </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="5"
                [(ngModel)]="qoutesForm.description"
              ></textarea>
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->

        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <button class="custom-btn" (click)="send()">
                {{ "Submit" | translate }}
              </button>
            </div>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
</section>
