<section class="home-page globle-page">
  <div class="container page-body-wrapper">
    <div class="row m-0">
      <div class="col-lg-8 align-self-center">
        <div class="dec">
          <!-- <h1>{{ "title_home" | translate }}</h1>
          <p>
            {{ "dec_home" | translate }}
          </p> -->
          <h1
            innerHTML="{{
              lang == 'en'
                ? HomeArr.data?.firstSection[0]?.title_en
                : HomeArr.data?.firstSection[0]?.title_ar
            }}"
          ></h1>
          <p
            innerHTML="{{
              lang == 'en'
                ? HomeArr.data?.firstSection[0]?.description_en
                : HomeArr.data?.firstSection[0]?.description_ar
            }}"
          ></p>
        </div>
      </div>
      <!-- /col -->
      <div class="col-lg-4">
        <div class="track-card text-center mt-5 mt-md-0">
          <img src="../../assets/images/track.png" alt="" />
          <h2>
            {{ "Tracking_Shippment" | translate }}
          </h2>

          <input
            type="search"
            [(ngModel)]="code"
            placeholder="{{ 'input_home' | translate }}"
          />

          <button class="custom-btn" (click)="send()">
            {{ "Track" | translate }}
          </button>
        </div>
        <!-- /track-card -->
      </div>
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
</section>

<section class="home-service globle-border">
  <div class="container page-body-wrapper">
    <div class="row m-0">
      <div class="col-lg-4" *ngFor="let item of HomeArr.data?.sevicesSection">
        <div class="icon">
          <img
            [src]="
              item.id == 4
                ? '../../assets/images/icon-1.png'
                : item.id == 5
                ? '../../assets/images/icon-2.png'
                : '../../assets/images/icon-3.png'
            "
            alt=""
          />
        </div>
        <!-- /icon -->
        <h3 innerHTML="{{ lang == 'en' ? item.title_en : item.title_ar }}"></h3>
        <p
          innerHTML="{{
            lang == 'en' ? item.description_en : item.description_ar
          }}"
        ></p>
      </div>
      <!-- /col -->

      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
  <!-- /container -->
</section>

<!-- /home-service -->
