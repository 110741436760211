import { ContactService } from './../service/contact.service';
import { HelperService } from './../service/helper.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm:any= {
    name:"",
    phone:"",
    email:"",
    message:""
  };

  mapCenter = {
    lat: 30.033333,
    lng: 31.233334
  };

lats;
lngs;
zoom = 15;
representativeId;
markers: marker[] = [];
address: string;
HomeArr;
lang
  constructor(private helper:HelperService,
    private contactService: ContactService,
    private toastrService:ToastrService ) { }

  ngOnInit(): void {
    this.helper.HomeArr.subscribe(arr => {this.HomeArr = arr;
      this.markers= [{
        lat: +arr.data?.contactDetails?.latitude,
        lng: +arr.data?.contactDetails?.longitude,
        draggable:false
      }];
      this.mapCenter = {
        lat: +arr.data?.contactDetails?.latitude,
        lng: +arr.data?.contactDetails?.longitude
      };
      this.lats =  +arr.data?.contactDetails?.latitude;
      this.lngs = +arr.data?.contactDetails?.longitude;


      // this.markers= [{
      //   lat: 30.6607954,
      //   lng: 31.0220417,
      //   draggable:false
      // }];
      // this.mapCenter = {
      //   lat: 30.6607954,
      //   lng: 31.0220417
      // };
      // this.lats =  30.6607954;
      // this.lngs = 31.0220417;




    console.log('this.HomeArr ',this.HomeArr );

    });

    this.helper.currentLang.subscribe(lang => this.lang = lang)




  }

  async send(){
    try{
      const formData = this.helper.jsonToFormData(this.contactForm);
      const res:any = await this.contactService.sendContact(formData);
      if(res.status.code ==200){
        if(this.lang == "en"){
          this.toastrService.success('Message Has Been Sent Successfully.','Done');
        }else{
          this.toastrService.success('تم إرسال الرسالة بنجاح.','تم ');

        }
        this.contactForm = {
          name:"",
          phone:"",
          email:"",
          message:""
        };
      }else{
        if(this.lang == "en"){
          this.toastrService.error(res.status.message,'Error');
        }else{
          this.toastrService.error(res.status.message,'خطأ');
        }
      }
    }catch(err){
      console.log(err);
    }
  }






}
interface marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}

