<div class="footer-content">
  <p
    style="font-weight: bold; text-transform: uppercase; font-size: 1.5rem"
    class="contact-p"
  >
    <b>
      {{
        lang == "en"
          ? HomeArr.data?.contactDetails?.contactName
          : HomeArr.data?.contactDetails?.contactNameAr
      }}
    </b>
  </p>
  <ul class="contact-list">
    <li>
      <div class="d-flex">
        <img src="../../assets/images/icon/location.png" class="mr-3" />
        <p>
          {{
            lang == "en"
              ? HomeArr.data?.contactDetails?.address
              : HomeArr.data?.contactDetails?.addressAr
          }}
        </p>
      </div>
    </li>
    <li>
      <div class="d-flex">
        <img src="../../assets/images/icon/email.png" class="mr-3" />
        <p>
          <a [attr.href]="'mailto:' + HomeArr.data?.contactDetails?.email">{{
            HomeArr.data?.contactDetails?.email
          }}</a>
        </p>
      </div>
    </li>
    <li>
      <div class="d-flex">
        <img src="../../assets/images/icon/phone.png" class="mr-3" />
        <p>
          <ng-container
            *ngFor="
              let item of HomeArr.data?.contactDetails?.mobiles;
              let i = index
            "
          >
            <span *ngIf="i != 0 && item != null">-</span> <bdi> {{ item }}</bdi>
          </ng-container>
        </p>
      </div>
    </li>
    <li>
      <div class="d-flex">
        <img src="../../assets/images/icon/web.png" class="mr-3" />
        <p>
          <a [attr.href]="HomeArr.data?.contactDetails?.website"
            >www.alawaelshipping.com</a
          >
        </p>
      </div>
    </li>
  </ul>
</div>
