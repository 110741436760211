<section class="service-page globle-page"></section>

<section class="home-service globle-border">
  <div class="container page-body-wrapper">
    <h4>{{ "Contact" | translate }}</h4>
    <div class="row">
      <div class="col-lg-6">
        <app-contact-list></app-contact-list>

        <app-socail-links></app-socail-links>
      </div>
      <!-- /col -->

      <div class="col-lg-6">
        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "name" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="contactForm.name"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->

        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Email" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="email"
                [(ngModel)]="contactForm.email"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->

        <div class="row align-items-center mb-4">
          <div class="col-lg-4">
            <label> {{ "Phone" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                [(ngModel)]="contactForm.phone"
              />
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->

        <div class="row mb-4">
          <div class="col-lg-4">
            <label class="pt-3"> {{ "Message" | translate }} <b>*</b> </label>
          </div>
          <!-- /col -->
          <div class="col-lg-8">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="5"
                [(ngModel)]="contactForm.message"
              ></textarea>
            </div>
            <!-- /form-group -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <button class="custom-btn" (click)="send()">
                {{ "Submit" | translate }}
              </button>
            </div>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
</section>

<!-- <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8133993225747!2d36.82749288572525!3d-1.285985735987388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d7e72fe6e5%3A0x7b34320441ee62ee!2sKencom%20House!5e0!3m2!1sar!2seg!4v1626113087917!5m2!1sar!2seg"
  class="w-100"
  height="500"
  style="border: 0"
  allowfullscreen=""
  loading="lazy"
></iframe> -->
<agm-map
  [latitude]="mapCenter?.lat"
  [longitude]="mapCenter?.lng"
  [zoom]="zoom"
  [disableDefaultUI]="false"
>
  <agm-marker
    *ngFor="let m of markers; let i = index"
    [latitude]="m.lat"
    [longitude]="m.lng"
    [label]="m.label"
    [markerDraggable]="m.draggable"
  >
    <agm-info-window>
      <strong>
        {{
          lang == "en"
            ? HomeArr.data?.contactDetails?.contactName
            : HomeArr.data?.contactDetails?.contactNameAr
        }}
        <br />
        {{
          lang == "en"
            ? HomeArr.data?.contactDetails?.address
            : HomeArr.data?.contactDetails?.addressAr
        }}
      </strong>
    </agm-info-window>
  </agm-marker>
</agm-map>
