import { LoaderService } from './service/loader.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  SwiperModule,
  SwiperConfigInterface,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccordionModule } from 'primeng/accordion';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { TrackingComponent } from './tracking/tracking.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { QuotesComponent } from './quotes/quotes.component';
import { ContactComponent } from './contact/contact.component';
import { ToastrModule } from "ngx-toastr";
import { SocailLinksComponent } from './socail-links/socail-links.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { AuthInterceptor } from './service/auth-interceptor';
import { LoaderInterceptor } from './service/loader-interceptor';
import { AgmCoreModule } from '@agm/core';
import { NgxCaptureModule } from 'ngx-capture';
import { BlogComponent } from './blog/blog.component';

// SwiperOptions from 'swiper' could also be used here instead of SwiperConfigInterface
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true,
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ServicesComponent,
    TrackingComponent,
    QuotesComponent,
    ContactComponent,
    SocailLinksComponent,
    ContactListComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDvQj5-9vkArSGzhY9EE1NY3wTHWvKn6pU',
      libraries: ['places']
  }),
    ToastrModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    SwiperModule,
    NgxSliderModule,
    NgxCaptureModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
